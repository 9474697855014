import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, keyframes } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";
import { Select } from "clutch/src/Select/Select.js";
import { mobile } from "clutch/src/Style/style.mjs";
import { Tag } from "clutch/src/Tag/Tag.js";
import { TextInput } from "clutch/src/TextInput/TextInput.js";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/constants.mjs";
import { getWikiLink } from "@/game-palworld/components/wiki.style.jsx";
import { ELEMENTS } from "@/game-palworld/constants/elements.mjs";
import { WORK_SUITABILITIES } from "@/game-palworld/constants/work-suitabilities.mjs";
import type { PassiveSkill } from "@/game-palworld/models/model-wiki.mjs";
import { getByInternalId } from "@/game-palworld/utils/get-by-internal-id.mjs";
import { TierChevron } from "@/game-palworld/utils/passives-utils.mjs";
import { useWikiData } from "@/game-palworld/utils/use-wiki-data.mjs";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SortOrder from "@/shared/SortOrder";
import { useQuery } from "@/util/router-hooks.mjs";

const cssPalCards = () => css`
  & {
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(var(--sp-1) * 60), 1fr)
    );
    align-items: center;
    flex-wrap: wrap;
    gap: var(--sp-6) var(--sp-4);
  }
`;

const cssPalCard = () => css`
  & {
    animation: ${keyframes`
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    `} 0.2s ease-in-out;
  }

  .pal-img {
    position: relative;
    height: calc(var(--sp-1) * 40);
    border-radius: var(--br-lg);
    background-color: var(--shade7);

    overflow: clip;

    border: 1px solid var(--shade3-15);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:hover,
    &:focus {
      outline: 2px solid var(--shade4);
      outline-offset: 2px;
    }

    &::after {
      content: "";
      display: block;
      height: var(--sp-1);
      position: absolute;

      bottom: 0;
      left: 0;
      right: 0;

      background: linear-gradient(
        to right,
        var(--element-primary) 33%,
        var(--element-secondary) 66%
      );
    }
  }

  .pal-name {
    font-size: var(--sp-2);
    font-weight: bold;
  }

  .work-icon {
    width: var(--sp-5);
    height: var(--sp-5);
  }
`;

const workOptions = Object.entries(WORK_SUITABILITIES).reduce(
  (acc, [k, v]) => {
    acc.push({ value: k, text: v.label, image: v.src });
    return acc;
  },
  [
    {
      value: "",
      text: ["palworld:work.all", "All Work Skills"] as Translation,
      image: "",
    },
  ],
);

const elementOptions = Object.entries(ELEMENTS).reduce(
  (acc, [k, v]) => {
    acc.push({ value: k, text: v.label, image: v.imageSrc });
    return acc;
  },
  [
    {
      value: "",
      text: ["palworld:element.all", "All Elements"] as Translation,
      image: "",
    },
  ],
);

const sortOptions = [
  {
    value: "paldeck",
    text: ["palworld:sort.paldeck", "Paldeck"] as Translation,
    fn: (a, b) => {
      if (a.paldeckId !== b.paldeckId) return a.paldeckId - b.paldeckId;
      return a.paldeckIdSuffix.localeCompare(b.paldeckIdSuffix);
    },
  },
  {
    value: "name",
    text: ["palworld:sort.name", "Name"] as Translation,
    fn: (a, b) => a.label[0].localeCompare(b.label[0]),
  },
];

const cssPageHeader = () => css`
  & {
    padding-block: var(--sp-1) var(--sp-5);
    border-bottom: 1px solid var(--shade6-50);
  }
`;

const searchContainer = () => css`
  display: flex;
  flex-direction: row;
  gap: var(--sp-2);

  ${mobile} {
    flex-direction: column;

    .selectButton {
      width: 100%;
    }
  }
`;

const PalsLanding = () => {
  const { t } = useTranslation();
  const { pals, passives } = useWikiData();

  const [elementFilter, setElementFilter] = useQuery<string>("element", "");
  const [workFilter, setWorkFilter] = useQuery<string>("work", "");
  const [nameFilter, setNameFilter] = useQuery<string>("name", "");
  const [passiveFilter, setPassiveFilter] = useQuery<string>("passive", "");
  const [sortMode, setSortMode] = useQuery<string>("sort", "paldeck");
  const [sortOrder, setSortOrder] = useQuery<"ASC" | "DESC">("order", "ASC");

  const passiveOptions = useMemo(() => {
    const options = [
      {
        value: "",
        text: ["palworld:passives.all", "All Passives"] as Translation,
        icon: null,
        tier: 4,
      },
    ];
    if (!pals || !passives) return options;
    const uniquePalPassives = Object.values(pals).reduce((acc, pal) => {
      pal.defaultPassives.forEach((passive) => {
        acc.add(passive);
      });
      return acc;
    }, new Set<string>());
    return Array.from(uniquePalPassives)
      .map<[string, PassiveSkill]>((passive) => [
        passive,
        getByInternalId(passive, passives),
      ])
      .reduce((acc, [k, v]) => {
        acc.push({
          value: k,
          text: v.label,
          icon: TierChevron({ tier: v.tier as number, width: 20 }),
          tier: v.tier,
        });
        return acc;
      }, options)
      .sort((a, b) => a.text[1].localeCompare(b.text[1]))
      .sort((a, b) => b.tier - a.tier);
  }, [pals, passives]);

  const filteredPals = useMemo(() => {
    if (!pals) return [];
    let filteredPals = Object.values(pals);

    const filters = [
      elementFilter && ((pal) => pal.elements.includes(elementFilter)),
      workFilter && ((pal) => pal.workSutiability[workFilter] > 0),
      nameFilter && ((pal) => pal.label.some((l) => l.includes(nameFilter))),
      passiveFilter && ((pal) => pal.defaultPassives.includes(passiveFilter)),
    ];
    filters.forEach((filter) => {
      if (!filter) return;
      filteredPals = filteredPals.filter(filter);
    });

    if (sortMode) {
      filteredPals = filteredPals.sort(
        sortOptions.find((o) => o.value === sortMode).fn,
      );
    }

    if (sortOrder === "DESC") {
      filteredPals = filteredPals.reverse();
    }

    return filteredPals;
  }, [
    elementFilter,
    nameFilter,
    pals,
    passiveFilter,
    sortMode,
    sortOrder,
    workFilter,
  ]);

  const titleTrans = IS_APP
    ? t("palworld:pals", "Pals")
    : t("palworld:pals.seoWeb", "Pals - Best Pals Palworld");

  if (!pals)
    return (
      <Container className="flex column gap-sp-4">
        <PageHeader title={titleTrans} className={cssPageHeader()} />
        <div className={searchContainer()}>
          <TextInput
            disabled
            placeholder={t("common:search", "Search")}
            defaultValue={nameFilter}
          />
          <Select
            disabled
            options={elementOptions}
            selected={elementFilter || ""}
            onChange={() => {}}
          />
          <Select
            disabled
            options={workOptions}
            selected={workFilter || ""}
            onChange={() => {}}
          />
          <Select
            disabled
            options={passiveOptions}
            selected={passiveFilter || ""}
            onChange={() => {}}
          />
          <div className="margin-inline-start-auto flex gap-sp-2 align-center">
            <span className="type-subtitle shade2">
              {t("palworld:sortColon", "Sort:")}
            </span>
            <Select
              disabled
              options={sortOptions}
              selected={sortMode}
              onChange={() => {}}
            />
            <SortOrder disabled sortOrder={sortOrder} />
          </div>
        </div>
        <div className={cssPalCards()}>
          {new Array(8).fill(0).map((_, i) => (
            <div key={i} style={{ height: "254px" }}>
              <Card loading style={{ height: "160px" }} />
            </div>
          ))}
        </div>
      </Container>
    );

  return (
    <Container className="flex column gap-sp-4">
      <PageHeader title={titleTrans} className={cssPageHeader()} />
      <div className={searchContainer()}>
        <TextInput
          placeholder={t("common:search", "Search")}
          onChange={(e) => setNameFilter(e.target.value)}
          value={nameFilter}
        />
        <Select
          options={elementOptions}
          selected={elementFilter || ""}
          onChange={(v) => setElementFilter(v || undefined)}
        />
        <Select
          options={workOptions}
          selected={workFilter || ""}
          onChange={(v) => setWorkFilter(v || undefined)}
        />
        <Select
          options={passiveOptions}
          selected={passiveFilter || ""}
          onChange={(v) => setPassiveFilter(v || undefined)}
        />
        <div className="margin-inline-start-auto flex gap-sp-2 align-center">
          <span className="type-subtitle shade2">
            {t("palworld:sortColon", "Sort:")}
          </span>
          <Select
            options={sortOptions}
            selected={sortMode}
            onChange={(v) => setSortMode(v || undefined)}
          />
          <SortOrder sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </div>
      </div>
      <div className={cssPalCards()}>
        {filteredPals.map((pal) => {
          return (
            <div
              key={pal.id}
              className={`${cssPalCard()} flex column gap-sp-3`}
              style={{
                "--element-primary": ELEMENTS[pal.elements[0]]?.color || "",
                "--element-secondary":
                  ELEMENTS[pal.elements[1] || pal.elements[0]]?.color || "",
              }}
            >
              <a className="pal-img" href={getWikiLink(`pal:${pal.id}`)}>
                <img src={`${appURLs.CDN_PLAIN}/${pal.imageUri}`}></img>
              </a>
              <div className="flex column">
                <div className="flex justify-between align-center">
                  <span className="type-title--bold">{t(...pal.label)}</span>
                  <span className="type-subtitle shade3">
                    {`#${pal.paldeckId.toString().padStart(3, "0")}${
                      pal.paldeckIdSuffix
                    }`}
                  </span>
                </div>
                <div className="pal-elements flex gap-sp-1">
                  {pal.elements.map((elementName) => {
                    return (
                      <Tag
                        size="small"
                        iconLeft={
                          <img
                            src={`${appURLs.CDN_PLAIN}/palworld/img/Elements/${elementName}.webp`}
                            style={{
                              // sic: undo what's done in Tag (?)
                              marginLeft: "calc(var(--sp-1) * 1)",
                            }}
                          />
                        }
                        text={t(...ELEMENTS[elementName].label)}
                        key={elementName}
                        color={ELEMENTS[elementName].color}
                      />
                    );
                  })}
                </div>
              </div>
              <div className="pal-workskills flex justify-between align-center">
                <div className="type-callout shade2">
                  {t("palworld:work", "Work")}
                </div>
                <div className="flex gap-sp-2">
                  {Object.entries(pal.workSutiability).map(
                    ([workName, level]) => {
                      if (workName === "oil_extraction") {
                        return null;
                      }
                      return (
                        <div className="flex align-center" key={workName}>
                          <img
                            className="work-icon"
                            src={`${appURLs.CDN_PLAIN}/palworld/img/Work/${workName}.webp`}
                          />
                          <span className="type-callout shade0">{level}</span>
                        </div>
                      );
                    },
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
};

export function meta() {
  return {
    title: [
      "palworld:palsLanding.title",
      "Best Pals Palworld - Combat, Breeding, Base",
    ] as Translation,
    description: [
      "palworld:palsLanding.description",
      "Dive into the world of Palworld with our comprehensive guide on Pals! Discover the best pals for every aspect of your adventure, from base building to intense combat. Whether you're seeking the most powerful pals for dominating battles, the top-tier pals for efficient farming and mining, or the best pals for breeding, our guide has you covered. Uncover the secrets to assembling a formidable Paldeck with our expertly curated pal tier list, ensuring you have the right companions for every challenge Palworld throws your way. Explore now and become the ultimate Pal master!",
    ] as Translation,
    subtitle: !IS_APP,
  };
}

export default PalsLanding;
